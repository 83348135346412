import http from "../utils/http";

// 配置管理
export const apiConfiguration = {
  projectTree() {
    return http.request({
      url: "/cemp-bms/project/current-user/tree2",
      method: "get",
    });
  },
};

export const apiSubarea = {
  list(bizProjectId, kpiTypeCode) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/listAll",
      method: "get",
      params: {
        bizProjectId,
        kpiTypeCode
      },
    });
  },
  delete(id) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/delete",
      method: "post",
      params: {
        id,
      },
    });
  },
  detail(id, kpiTypeCode) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/detail",
      method: "get",
      params: {
        id,
        kpiTypeCode
      },
    });
  },
  getDataBySpace(bizProjectId) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/space/import",
      method: "post",
      params: {
        bizProjectId,
      },
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/save",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/edit",
      method: "post",
      data,
    });
  },
  allDeviceList(bizProjectId) {
    return http.request({
      url: "/cemp-energy/project-cnf-subarea/devices/all",
      method: "get",
      params: {
        bizProjectId,
      },
    });
  },
};

export const apiSubitem = {
  list(bizProjectId, kpiTypeCode) {
    return http.request({
      url: "/cemp-energy/project-cnf-subitem/listAll",
      method: "get",
      params: {
        bizProjectId,
        kpiTypeCode
      },
    });
  },
  delete(id) {
    return http.request({
      url: "/cemp-energy/project-cnf-subitem/delete",
      method: "post",
      params: {
        id,
      },
    });
  },
  detail(id) {
    return http.request({
      url: "/cemp-energy/project-cnf-subitem/detail",
      method: "get",
      params: {
        id
      },
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-subitem/save",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-subitem/edit",
      method: "post",
      data,
    });
  },
  kpiDesc(code) {
    return http.request({
      url: "/cemp-energy/project-cnf-subitem/kpi/desc",
      method: "get",
      params: {
        code,
      },
    });
  },
};

export const apiConfigElectricity = {
  list(bizProjectId) {
    return http.request({
      url: "/cemp-energy/project-cnf-time-period/list",
      method: "get",
      params: {
        bizProjectId,
      },
    });
  },
  delete(projectId, time) {
    return http.request({
      url: "/cemp-energy/project-cnf-time-period/remove",
      method: "post",
      data: {
        projectId,
        time,
      },
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-time-period/save",
      method: "post",
      data,
    });
  },
  //固定类型电价新增
  addConstValue(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-electricity-price/save",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-energy/project-cnf-time-period/save",
      method: "post",
      data,
    });
  },
  detailInfo(bizProjectId) {
    return http.request({
      url: "/cemp-energy/project-cnf-electricity-price/detail",
      method: "get",
      params: {
        bizProjectId,
      },
    });
  },

};

export const apiConfigWater = {
  add(data) {
    return http.request({
      url: `/cemp-energy/project-cnf-water-fee/save`,
      method: "post",
      data,
    });
  },
  detail(params) {
    return http.request({
      url: `/cemp-energy/project-cnf-water-fee/detail`,
      method: "get",
      params,
    });
  },
};

export const apiConfigGas = {
  add(data) {
    return http.request({
      url: `/cemp-energy/project-cnf-gas-fee/save`,
      method: "post",
      data,
    });
  },
  detail(params) {
    return http.request({
      url: `/cemp-energy/project-cnf-gas-fee/detail`,
      method: "get",
      params,
    });
  },
};

export const apiConfigEnergy = {
  pv(data) {
    return http.request({
      url: `/cemp-energy/project-cnf-energy-sub-system/change/pv`,
      method: "post",
      data,
    });
  },
  storage(data) {
    return http.request({
      url: `/cemp-energy/project-cnf-energy-sub-system/change/storage`,
      method: "post",
      data,
    });
  },
  chargeStation(data) {
    return http.request({
      url: `/cemp-energy/project-cnf-energy-sub-system/change/chargeStation`,
      method: "post",
      data,
    });
  },
  detail(id) {
    return http.request({
      url: `/cemp-energy/project-cnf-energy-sub-system/configs/${id}`,
      method: "get"
    });
  },
};
